import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import FetchCSRServices from '../csr/FetchCSRServices'

export class CSRServiceList extends Component {
    static displayName = CSRServiceList.name;

    constructor(props) {
        super(props);
        this.state = { services: [], loading: true };
    }

    componentDidMount() {
        var dataFetch = FetchCSRServices('CINC');
        dataFetch.then((data) => {
            this.setState({ services: data, loading: false });
        })
        console.debug("CSRSERVICESLIST: " + JSON.stringify(this.props));
    }

    static getIcon(categoryText) {
        var retHTML = "bi-alarm"


        // Need to work out a better way to do this, but that's for another time.
        switch (categoryText) {
            case "COVID-19":
                retHTML = "bi-file-medical"
                break;
            case "Accessibility":
                retHTML = "bi bi-question-square"
                break;
            case "Bugs, Pests and Animals":
                retHTML = "bi-bug"
                break;
            case "Residential Buildings and Neighborhoods":
                retHTML = "bi-house-door"
                break;
            case "Public Safety":
                retHTML = "bi-shield-shaded"
                break;
            case "Public Records":
                retHTML = "bi-folder2-open"
                break;
            case "Commercial Buildings":
                retHTML = "bi-building"
                break;
            case "Graffiti, Litter and Dumping":
                retHTML = "bi-bricks"
                break;
            case "Construction":
                retHTML = "bi-cone-striped"
                break;
            case "Parks and Recreation":
                retHTML = "bi-binoculars"
                break;
            case "Roads and Traffic":
                retHTML = "bi-stoplights"
                break;
            case "Sidewalks, Crosswalks and Pedestrians":
                retHTML = "bi-people"
                break;
            case "Streetcar, Bikes and Scooters":
                retHTML = "bi-bicycle"
                break;
            case "Streets, Signs and Signals":
                retHTML = 'bi-signpost-split';
                break;
            case "Sewer and Water":
                retHTML = "bi-droplet-half"
                break;
            case "Renters":
                retHTML = "bi-house"
                break;
            case "Weeds, Trees and Greenspace":
                retHTML = "bi-tree"
                break;
            case "Trash, Recycling and Yard Waste":
                retHTML = "bi-trash"
                break;
            case "Parking and Vehicles":
                retHTML = "bi-truck"
                break;
            case "Other":
                retHTML = "bi-exclamation-circle"
                break;
            case "Elevators":
                retHTML = "bi-arrow-down-up";
                break;
            case "Health and Food Operations":
                retHTML = "bi-shop";
                break;
            case "Short term rentals":
                retHTML = "bi-briefcase";
                break;
            case "Human Services and Civil Rights":
                retHTML = "bi-bell"
                break;
            default:
        }


        return retHTML;
    }

    static generateSubtypeLink(subtype, userParam) {

        // Start looking through special cases

        //console.debug("Subtype: " + subtype.id + " URL: " + subtype.url);

        if (subtype.url != null && subtype.url != '') {
            return <a className="p-1 d-flex"
                      key={subtype.id}
                      href={subtype.url}>
                <div>{subtype.description}</div>
                <div className="ms-2"><FontAwesomeIcon icon={faChevronRight} /></div>
            </a>
        }
        else if (subtype.modal != null && subtype.modal != '') {
            //this.createModal(subtype.id, subtype.modal);
            return <a className="p-1 d-flex"
                    key={subtype.id}
                    onClick={() => alert(subtype.modal)}
                >
                    <div>{subtype.description}</div>
                    <div className="ms-2"><FontAwesomeIcon icon={faChevronRight} /></div>
                </a>
        } else {
            // Base level functionality
            return <a className="p-1 d-flex"
                      key={subtype.id}
                      href={"https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=" + subtype.id + userParam}>
                <div>{subtype.description}</div>
                <div className="ms-2"><FontAwesomeIcon icon={faChevronRight} /></div>
            </a>
        }
    }

    static renderCSRTable(services,props) {
        //var userParam = "";
        //if (this.props.connectUser != undefined) {
        //    var connectUser = this.props.connectUser;
        //    userParam = "&usr=" + connectUser.firstName + "%20" + connectUser.lastName + "&userOffice=" + connectUser.csrGroups[0]
        //}
        //console.debug("?: " + JSON.stringify(props));
        var userParam = "";
        //if (props.connectUser != undefined) {
        //    var connectUser = props.connectUser;
        //    var theGroup = "";
        //    if (connectUser.csrGroups != null) {
        //        theGroup = connectUser.csrGroups[0];
        //    }
        //    userParam = "&usr=" + connectUser.firstName + "%20" + connectUser.lastName + "&userOffice=" + theGroup
        //}
        return (
            <div className='accordion mt-3' id='accordionServices'>                
                {services.map(service => {
                    if (service.id != null) {
                        return <div className='accordion-item mb-3 border-top' key={service.id}>
                            <h2 className="accordion-header" id={"heading" + service.id.substr(0, 8).trim().split(',').join('').replace(/ /g, "").toUpperCase()}>
                                <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={"#collapse" + service.id.substr(0, 8).trim().split(',').join('').replace(/ /g, "").toUpperCase()}>
                                    <i className={this.getIcon(service.id)} style={{ fontSize: '2rem', color: 'cornflowerblue' }}></i>
                                    {service.id}
                                </button>
                            </h2>
                            <div id={"collapse" + service.id.substr(0, 8).trim().split(',').join('').replace(/ /g, "").toUpperCase()} className="accordion-collapse collapse" data-bs-parent="#accordionServices">
                                <div className="accordion-body">
                                    <div className="list-group list-group-flush p-3 ExpServCat">

                                        <div className='accordion mt-3' id={'accordionSubGroups' + service.id.substr(0, 5).trim().split(',').join('').toUpperCase()}>

                                            {service.subgroups && service.subgroups.map(subgroup => {
                                                return <div className="accordion-item mb-3 border-top sub-group">
                                                    <h3 className="accordion-header" id={"sub-heading" + subgroup.id.substr(0, 3).toUpperCase()}>
                                                        <button className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={"#collapse" + subgroup.id.substr(0, 3).toUpperCase()}>
                                                            <i className={this.getIcon(subgroup.id)} style={{ fontSize: '2rem', color: 'cornflowerblue' }}></i>
                                                            {subgroup.id}
                                                        </button>
                                                    </h3>
                                                    <div id={"collapse" + subgroup.id.substr(0, 3).toUpperCase()}
                                                        className="accordion-collapse collapse list-group list-group-flush p-3 ExpServCat"
                                                         data-bs-parent={'#accordionSubGroups' + service.id.substr(0, 5).trim().split(',').join('').toUpperCase()}>
                                                        {subgroup.subtypes.map(subtype => {
                                                            /* For implementing what Kurt wants, with certain of the links having different behavior,
                                                             * I'm thinking that right here we should break this out into its own function.
                                                             */

                                                            return this.generateSubtypeLink(subtype, userParam)

                                                        })}
                                                    </div>
                                                </div>
                                            })}

                                        </div>

                                        {
                                            service.subtypes.map(subtype => {
                                                return this.generateSubtypeLink(subtype, userParam)
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                })
                }
            </div>
        );
    }



    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : CSRServiceList.renderCSRTable(this.state.services,this.props.props);

        return (
            <div>
                {contents}
            </div>
        );
    }
}
