import React, { useRef } from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import { Grid, Box, Typography, Button } from '@mui/material';
import { grey, blueGrey } from '@mui/material/colors';

import { CSRServiceList } from './features/csr/CSRServiceList';
import { CSRServiceListTypeahead } from './features/csr/CSRServiceListTypeahead';
import QuickSearchPanel from './components/QuickSearchPanel';

import './App.css';
import RSSBanner from './components/RSSBanner';

function App() {

    const frequentServices = useRef<null | HTMLDivElement>(null);

    const executeScroll = () => frequentServices?.current?.scrollIntoView({ behavior: 'smooth' })
    var logo = "/311/logo_311cincy_3c.png";
    var cincLogo = "/311/citylogo_fullcolor.png";
    var cinc311TitleColor = "#00457c"

    let date = new Date();
    let curMonth = date.getMonth();
    let curDay = date.getDate();

    //var logo = "/311/logo_311cincy_295.png";
    //var cincLogo = "/311/citylogo_darkblue.png";
    //var cinc311TitleColor = "#00457c"

    return (
        <Grid container spacing={2} style={{ paddingTop: '20px' }}>
            <Grid item xs={12} className="border_bottom_darkblue">
                <Grid container spacing={3}>
                    <Grid item xs>
                        <img id="logo311" src={logo} className="position-absolute" alt="311 logo" />
                    </Grid>
                    <Grid item xs={6}>
                        <h1 id="cheader" style={{ color: cinc311TitleColor }} className="display-2 text-center">311Cincy</h1>
                    </Grid>
                    <Grid item xs>
                        <img id="logoCity" src={cincLogo} style={{ float: 'right' }} onClick={() => window.open("https://www.cincinnati-oh.gov/cityofcincinnati/", "_blank")} alt="Cincinnati logo" />
                    </Grid>
                </Grid>

                {/*<Box py={2} ml={2}>*/}
                {/*    <Button type="submit" variant="outlined"*/}
                {/*        size="large"*/}
                {/*        color="default"*/}
                {/*        component={Link} to={{ pathname: "/constituentAffairs" }}*/}
                {/*    >Cancel</Button>*/}
                {/*</Box>*/}
            </Grid>
            {/*<Grid className="py-5 bg-light border_bottom_orange" item xs={12}>*/}
            {/*    <Box pt={2} display="flex" justifyContent="center">*/}
            {/*        <h5 className="display-5 text-center">Four ways to request services</h5></Box>*/}
            {/*    <Box pt={1} display="flex" justifyContent="center">*/}

            {/*        <div className="modeLinks d-flex flex-row flex-wrap justify-content-center">*/}

            {/*            <a className="p-4 m-2" href="tel:513-765-1212">*/}
            {/*                <i className="bi-telephone-fill d-flex justify-content-center" style={{ fontSize: '3rem', color: 'lightgray' }}></i>*/}
            {/*                <p>Call 311</p>*/}
            {/*            </a>*/}
            {/*            <a id="modeLinks_app" className="p-4 m-2" href="https://www.cincinnati-oh.gov/cityofcincinnati/cincinnati-city-hall-mobile/">*/}
            {/*                <i className="bi-phone-vibrate d-flex justify-content-center" style={{ fontSize: '3rem', color: '#fb7120' }}></i>*/}
            {/*                <p>311Cincy App</p>*/}
            {/*            </a>*/}
            {/*            <a id="modeLinks_website" className="p-4 m-2" onClick={executeScroll}>*/}
            {/*                <i className="bi-display d-flex justify-content-center" style={{ fontSize: '3rem', color: '#00aeef' }}></i>*/}
            {/*                <p>Select a Service</p>*/}
            {/*            </a>*/}
            {/*            */}{/*<a id="modeLinks_emailps" className="p-4 m-2" href="mailto:311cincy@cincinnati-oh.gov?subject=Requesting 311 Service">*/}
            {/*            */}{/*    <i className="bi-envelope-fill d-flex justify-content-center" style={{ fontSize: '3rem', color: '#CFDB5D' }}></i>*/}
            {/*            */}{/*    <p>Email 311</p>*/}
            {/*            */}{/*</a>*/}
            {/*            <a className="p-4 m-2" href="https://twitter.com/311cincy">*/}
            {/*                <i className="bi-twitter d-flex justify-content-center" style={{ fontSize: '3rem', color: 'dimgray' }}></i>*/}
            {/*                <p>Follow us @311cincy</p>*/}
            {/*            </a>*/}

            {/*        </div>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}


            <Grid className="py-5 bg-light border_bottom_orange" justifyContent="center" container xs={12}>
                <Grid>
                    <Box pt={2} display="flex" justifyContent="center"><h5 className="display-5 text-center">Request Service</h5></Box>
                    <Box pt={1} display="flex" justifyContent="center">
                        <div className="modeLinks d-flex flex-row flex-wrap justify-content-center">

                            <a id="modeLinks_call" className="p-4 m-2" href="tel:513-765-1212">
                                <i className="bi-telephone-fill d-flex justify-content-center" style={{ fontSize: '3rem', color: '#c4ce37' }}></i>
                                <p>Call 311</p>
                            </a>
                            <a id="modeLinks_app" className="p-4 m-2" href="https://www.cincinnati-oh.gov/cityofcincinnati/cincinnati-city-hall-mobile/">
                                <i className="bi-phone-vibrate d-flex justify-content-center" style={{ fontSize: '3rem', color: '#fb7120' }}></i>
                                <p>311Cincy App</p>
                            </a>
                            <a id="modeLinks_website" className="p-4 m-2" onClick={executeScroll}>
                                <i className="bi-display d-flex justify-content-center" style={{ fontSize: '3rem', color: '#00aeef' }}></i>
                                <p>Select a Service</p>
                            </a>
                            {/*<a id="modeLinks_emailps" className="p-4 m-2" href="mailto:311cincy@cincinnati-oh.gov?subject=Requesting 311 Service">*/}
                            {/*    <i className="bi-envelope-fill d-flex justify-content-center" style={{ fontSize: '3rem', color: '#CFDB5D' }}></i>*/}
                            {/*    <p>Email 311</p>*/}
                            {/*</a>*/}
                        </div>
                    </Box>
                </Grid>
                <Grid xs={1}></Grid>
                <Grid>
                    <Box pt={2} display="flex" justifyContent="center"><h5 className="display-5 text-center">Learn More</h5></Box>
                    <Box pt={1} display="flex" justifyContent="center">

                        <div className="modeLinks d-flex flex-row flex-wrap justify-content-center">

                            <a id="modeLinks_info" className="p-4 m-2" href="https://www.cincinnati-oh.gov/ecc/about-ecc/311/">
                                <i className="bi-info-circle d-flex justify-content-center" style={{ fontSize: '3rem', color: 'darkblue' }}></i>
                                <p>About 311</p>
                            </a>
                            <a id="modeLinks_collection" className="p-4 m-2" href="https://cagismaps.hamilton-co.org/cincinnatiServices/ServiceSchedules/">
                                <i className="bi-calendar-event d-flex justify-content-center" style={{ fontSize: '3rem', color: 'brown' }}></i>
                                <p>Collection Schedule</p>
                            </a>
                            {curMonth < 2 || curMonth >= 10 || (curMonth == 2 && curDay <= 15) ? (
                                <a id="modeLinks_website" className="p-4 m-2" href="https://insights.cincinnati-oh.gov/stories/s/Snow-Plow-Tracker/usdr-2hia/">
                                    <i className="bi-cloud-snow d-flex justify-content-center" style={{ fontSize: '3rem', color: '#00aeef' }}></i>
                                    <p>Snow Plow Routes</p>
                                </a>
                            ) :
                                <a id="modeLinks_yardwaste" className="p-4 m-2" href="https://www.cincinnati-oh.gov/public-services/yard-waste/">
                                    <i className="bi-trash3 d-flex justify-content-center" style={{ fontSize: '3rem', color: '#269b17' }}></i>
                                    <p>Yard Waste Information</p>
                                </a>
                            }


                            {/*<a id="modeLinks_emailps" className="p-4 m-2" href="mailto:311cincy@cincinnati-oh.gov?subject=Requesting 311 Service">*/}
                            {/*    <i className="bi-envelope-fill d-flex justify-content-center" style={{ fontSize: '3rem', color: '#CFDB5D' }}></i>*/}
                            {/*    <p>Email 311</p>*/}
                            {/*</a>*/}
                            {/*<a className="p-4 m-2" href="https://twitter.com/311cincy">*/}
                            {/*    <i className="bi-twitter d-flex justify-content-center" style={{ fontSize: '3rem', color: 'dimgray' }}></i>*/}
                            {/*    <p>Follow us @311cincy</p>*/}
                            {/*</a>*/}

                        </div>
                    </Box>
                </Grid>
            </Grid>



            {/*<Grid className="py-5 bg-light border_bottom_orange" item xs={12}>*/}
            {/*    <Box pt={2} display="flex" justifyContent="center">*/}
            {/*        <h5 className="display-5 text-center">Four ways to request services</h5></Box>*/}
            {/*    <Box pt={1} display="flex" justifyContent="center">*/}

            {/*        <div className="modeLinks d-flex flex-row flex-wrap justify-content-center">*/}

            {/*            <a className="p-4 m-2" href="tel:513-765-1212">*/}
            {/*                <i className="bi-telephone-fill d-flex justify-content-center" style={{ fontSize: '3rem', color: 'lightgray' }}></i>*/}
            {/*                <p>Call 311</p>*/}
            {/*            </a>*/}
            {/*            <a id="modeLinks_app" className="p-4 m-2" href="https://www.cincinnati-oh.gov/cityofcincinnati/cincinnati-city-hall-mobile/">*/}
            {/*                <i className="bi-phone-vibrate d-flex justify-content-center" style={{ fontSize: '3rem', color: '#fb7120' }}></i>*/}
            {/*                <p>311Cincy App</p>*/}
            {/*            </a>*/}
            {/*            <a id="modeLinks_website" className="p-4 m-2" onClick={executeScroll}>*/}
            {/*                <i className="bi-display d-flex justify-content-center" style={{ fontSize: '3rem', color: '#00aeef' }}></i>*/}
            {/*                <p>Select a Service</p>*/}
            {/*            </a>*/}
            {/*            */}{/*<a id="modeLinks_emailps" className="p-4 m-2" href="mailto:311cincy@cincinnati-oh.gov?subject=Requesting 311 Service">*/}
            {/*            */}{/*    <i className="bi-envelope-fill d-flex justify-content-center" style={{ fontSize: '3rem', color: '#CFDB5D' }}></i>*/}
            {/*            */}{/*    <p>Email 311</p>*/}
            {/*            */}{/*</a>*/}
            {/*            <a className="p-4 m-2" href="https://twitter.com/311cincy">*/}
            {/*                <i className="bi-twitter d-flex justify-content-center" style={{ fontSize: '3rem', color: 'dimgray' }}></i>*/}
            {/*                <p>Follow us @311cincy</p>*/}
            {/*            </a>*/}

            {/*        </div>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}

            <RSSBanner />

            <QuickSearchPanel />


            {/*Quick Links*/}
            <Grid item xs={12} className="pt-5" id="frequentServices" ref={frequentServices}>
                <Box pt={2} display="flex" justifyContent="center">
                    <h5 className="display-5 text-center">Frequently Used Service Requests</h5>
                </Box>
                <Box pt={1} display="flex" justifyContent="center">
                    <h3>Select from one of the links below</h3>
                </Box>
            </Grid>
            <Grid item xs={12} className="pb-5 row ">
                <Box id="csr_qlinks" display="flex"
                    boxShadow={2} border={1} borderRadius={8} borderColor="grey.400" className=" p-3 flex-wrap justify-content-end justify-content-md-center">
                    <div className="list-group list-group-flush align-items-end col-md-6">
                        <a href={"https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=PTHOLE"}
                            className="d-flex justify-content-end list-group-item list-group-item-action">
                            <div className="me-2 text-end">Fix a Pothole</div>
                            <div><i className="bi bi-chevron-right"></i></div>
                        </a>
                        <a href={"https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=GRFITI"}
                            className="d-flex justify-content-end list-group-item list-group-item-action">
                            <div className="me-2 text-end">Remove Graffiti</div>
                            <div><i className="bi bi-chevron-right"></i></div>
                        </a>
                        <a href={"https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=RF-COLLT"}
                            className="d-flex justify-content-end list-group-item list-group-item-action">
                            <div className="me-2 text-end">Request Trash - Missed Pickup</div>
                            <div><i className="bi bi-chevron-right"></i></div>
                        </a>
                        <a href={"https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=TRASH-I"}
                            className="d-flex justify-content-end list-group-item list-group-item-action">
                            <div className="me-2 text-end">Report Improper Trash Setout</div>
                            <div><i className="bi bi-chevron-right"></i></div>
                        </a>
                    </div>
                    <div className="list-group list-group-flush align-items-start col-md-6 ps-3">
                        <a href={"https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=MTL-FRN"}
                            className="d-flex justify-content-end list-group-item list-group-item-action">
                            <div className="me-2 text-end">Pick up Bulky Items</div>
                            <div><i className="bi bi-chevron-right"></i></div>
                        </a>
                        <a href={"https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=TLGR-ALL"}
                            className="d-flex justify-content-end list-group-item list-group-item-action">
                            <div className="me-2 text-end">Report Tall Grass/Weeds</div>
                            <div><i className="bi bi-chevron-right"></i></div>
                        </a>
                        <a href={"https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=LITR-PRV"}
                            className="d-flex justify-content-end list-group-item list-group-item-action">
                            <div className="me-2 text-end">Report Litter on Private Property</div>
                            <div><i className="bi bi-chevron-right"></i></div>
                        </a>
                        <a href={"https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=STRTLITE"}
                            className="d-flex justify-content-end list-group-item list-group-item-action">
                            <div className="me-2 text-end">Repair a Street Light</div>
                            <div><i className="bi bi-chevron-right"></i></div>
                        </a>
                    </div>
                </Box>
            </Grid>



            {/*Search Online Services*/}
            {/*<Grid item xs={12} className="mt-1 border_bottom_blue">*/}
            {/*    <Box my={5} pl={5} bgcolor={grey[100]}*/}
            {/*        boxShadow={2} border={1} borderRadius={8} borderColor="grey.400">*/}
            {/*        <Box pt={2} >*/}
            {/*            <h5 className="display-6 text-center"*/}
            {/*            >Start Request using Keyword</h5>*/}
            {/*        </Box>*/}

            {/*        <Typography component="div">*/}
            {/*            <Box pt={1} fontWeight="fontWeightLight" >Hint: Type in keywords like trash, weeds, building, rights, etc</Box>*/}
            {/*        </Typography>*/}

            {/*        <Box pt={2} mb={5}>*/}
            {/*            <div className="d-flex flex-wrap pb-3">*/}
            {/*                <CSRServiceListTypeahead />*/}
            {/*            </div>*/}
            {/*        </Box>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}

            <Grid item xs={12} className="pt-5 border_bottom_blue bg-light px-3">
                <Box pt={2} >
                    <h5 className="display-5 text-center">Explore Services by Category</h5> {/*******************************/}
                    <h5 className="display-8 text-center"> The information you submit may be subject to Ohio Public Records laws.</h5>
                </Box>
                <div className="d-flex flex-wrap pb-3">
                    <CSRServiceListTypeahead />
                </div>
                <Typography component="div">
                    <Box mb={4} pt={1} fontWeight="fontWeightLight" >
                        <p>
                            Hint: Click to expand categories to see available Service Requests.
                            Then click on the Service Request type to initiate.
                        </p>
                    </Box>
                </Typography>

                <CSRServiceList />
                {/*Alright, so wherever this.props comes from has a bunch of
                     * mismatches for types etc, also need to figure out where it's getting its subtype descriptions*/}
            </Grid>
        </Grid>
    );
}

export default App;
