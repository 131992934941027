import * as React from 'react';
import { useSelector } from "react-redux"
//import { useHistory } from 'react-router-dom';
import { FormControl, Paper, InputAdornment, InputLabel, Grid, Box, IconButton, Input, Typography, Button, Fade, Backdrop } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { grey } from "@mui/material/colors"
import Skeleton from '@mui/material/Skeleton';
import DataTable from '../features/csr/DataTable';
import { CSRServiceListTypeahead } from '../features/csr/CSRServiceListTypeahead';
import { createStyles, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Modal } from 'bootstrap';


interface CSRApiData {
    name: string;
    srNumber: string;
    location: string;
    srDescription: string;
    dataStatus: string;
    dateEntered: string;
    responseDue: string;
    description: string;
}
class RequestCSRData {
    async getData(searchType: string, searchValue: string, svrDomain: string) {

        let response = await fetch(svrDomain + "/api/v1/CSRConfiguration/SearchCSRData?searchType=" + searchType + "&searchValue=" + searchValue)

        let data: CSRApiData = await response.json();
        return data;
    }
}

class RequestSRDetails {
    async getSRDetails(searchValue: string) {

        let response = await fetch("https://cagismaps.hamilton-co.org/CSROnlineServices/api/CinciServices/GetSRDetails/" + searchValue)

        let data = await response;
        return data;
    }
}


const $ = require('jquery');
$.DataTable = require('datatables.net');

const BuildEmptyTable = () => {
    console.debug("build empty table");

    return (
        <div>
            <div id="searchResultsContainer" >
                <Box mb={2}>
                    <Typography variant="h6">Search Results</Typography>
                </Box>
                <DataTable />
            </div>
            <div id="searchResultsSkeleton" style={{ display: "none" }}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center">
                    <Skeleton variant="text" width="75%" animation="wave" />
                    <Skeleton variant="rectangular" width='75%' height={300} animation="wave" />
                </Grid>
            </div>
        </div>
    );
}

export default function CSR_QuickSearchPanel() {


    const [srSearchResponse, setNewSRResults] = React.useState("");

    //  const history = useHistory();
    //  console.debug(history);

    //const lclSvrDomainObj = useSelector((state: any) => state.serverURLS);
    //const lclSvrDomain = lclSvrDomainObj.serverDomain;
    const lclSvrDomain = "https://cagis.hamilton-co.org/CSRServices";
    //const lclSvrDomain = "https://cagis.hamilton-co.org/CSRServicesTest";
    //const lclSvrDomain = "https://localhost:44385";

    let SRSearchResponse = "";
    const rebuildResults = (searchType: string, searchValue: string) => {
        const requestCSRData = new RequestCSRData();

        requestCSRData.getData(searchType, searchValue, lclSvrDomain).then((dataSet) => {

            //console.debug("dataSet: " + JSON.stringify(dataSet));
            const table = $(".dataTables_wrapper table#searchResults").DataTable();
            table.clear();
            table.rows.add(dataSet);
            table.draw();

            const tableBody = $(".dataTables_wrapper table#searchResults tbody")

            $("#searchResultsContainer").show();
            $("#searchResultsSkeleton").hide();

            tableBody.off();
            tableBody.on('click', 'tr', (sel: { currentTarget: any; }) => {
                var data = table.row(sel.currentTarget).data();
                let resURL = "";
                setNewSRResults("");
                //handleShow();
                const element = document.getElementById('srDetailsModal') as HTMLElement;
                const myModal = new (Modal as any)(element);
                myModal.show();
                $("#srDetailsSkeleton").show();
                if (data["srNumber"] && data["srNumber"].substring(0, 2) == "SR") {
                    const requestSRDetails = new RequestSRDetails();
                    var xmlRequest = requestSRDetails.getSRDetails(data["srNumber"]);
                    xmlRequest.then(function (srDetailResp) {
                        if (srDetailResp.ok) {
                            srDetailResp.json().then(json => {
                                console.log(json);
                                $("#srDetailsSkeleton").hide();
                                setNewSRResults(json);


                            });
                        }

                    })
                    //resURL = "https://cagismaps.hamilton-co.org/CSROnlineServices/api/CinciServices/GetSRDetails/" + data["srNumber"];
                    //window.open(resURL);

                } else {
                    resURL = "https://cagismaps.hamilton-co.org/PropertyActivity/CAGISReport?APD=" + data["srNumber"];
                    window.open(resURL);
                }
                //console.log("tableBody.on('click'=", resURL)

                //history.push(resURL);
                //window.location.href = 'resolutionActivities/' + data["srNumber"];
            });


        });
    }

    const processSearch = (target: { parentElement: { parentElement: { textContent: any; }; }; value: string; }) => {
        $("#searchResultsContainer").show();
        $("#searchResultsContainer").hide();
        var parentTextContent = target.parentElement.parentElement.textContent;
        switch (true) {
            case (parentTextContent.indexOf("Request #") != -1):
                rebuildResults("request311", target.value);
                break;
            case (parentTextContent.indexOf("Address") != -1):
                rebuildResults("address311", target.value);
                break;
            case (parentTextContent.indexOf("Name") != -1):
                rebuildResults("name311", target.value);
                break;
            default:
        }
    }

    const handleKeyDown = async (e: { keyCode: number; target: { parentElement: { parentElement: { textContent: any; }; }; value: string; }; }) => {
        if (e.keyCode == 13) {
            processSearch(e.target);
        }
        var indexOfTarget = $("#searchInputsContainer").find("input").index(e.target)
        $("#searchInputsContainer").find("input").each(function (i: any, node: { value: string; }) {
            if (i != indexOfTarget) {
                node.value = "";
            }
        })
    }


    const SearchForm = (props: any) => {
        const handleMouseDownSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
            var inputTarget = $(event.target).parents(".MuiInputBase-root").children("input")
            processSearch(inputTarget[0]);
        };

        return (
            <Paper >
                <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">{props.searchLabel}</InputLabel>
                    <Input
                        id="outlined-adornment-password"
                        fullWidth={true}
                        disableUnderline={true}
                        onKeyDown={props.onKeydown}
                        style={{
                            padding: "0px 10px 7px 17px"
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    aria-label="search"
                                    onClick={handleMouseDownSearch}

                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Paper>
        )
    }
    var columns = [
        { data: "srNumber", title: "SRNumber" },
        { data: "srDescription", title: "Description" },
        { data: "location", title: "Address" },
        { data: "dataStatus", title: "Status" },
        { data: "dateEntered", title: "Entered", type: "date" }
    ];



    return (
        <>
            <Grid item xs={12} id="searchInputsContainer" >
                <Box my={5} pl={3} pr={3} pb={2} bgcolor={grey[100]}
                    boxShadow={2} border={1} borderRadius={8} borderColor="grey.400">

                    {/*Search Online Services*/}
                    <Grid container className="mt-1">
                        <Grid item md>
                                <Box pt={2} >
                                    <h5 className="display-6 text-center"
                                    >Begin Request</h5>
                                </Box>

                            <Typography component="div" className="display-6 text-center">
                                    <Box pt={1} fontWeight="fontWeightLight" >Hint: Type in keywords like trash, weeds, building, rights, etc</Box>
                                </Typography>

                            <Box pt={2} mb={5} > 
                                <div className="d-flex flex-wrap pb-3" style={{justifyContent: 'center' } }>
                                        <CSRServiceListTypeahead />
                                    </div>
                            </Box>
                            <Typography component="div" className="display-4 text-center">
                               The information you submit may be subject to Ohio Public Records laws.
                            </Typography>

                        </Grid>
                        <Grid item md={2}></Grid>
                        <Grid item md>
                            <Box pt={2} >
                                <h5 className="display-6 text-center"
                                >Search Existing Requests</h5>
                            </Box>

                            <Typography component="div">
                                {/*    <Box pt={1} fontWeight="fontWeightLight" >Hint: Do we need a hint here?</Box>*/}
                            </Typography>

                            <Box pt={2} display="flex" justifyContent="center" >
                                <div className="d-flex  pb-3 flex-wrap justify-content-center">
                                    <Grid item className="m-2">
                                        <SearchForm searchLabel={"Enter Exact Request #"} onKeydown={handleKeyDown} />
                                    </Grid>
                                    <Grid item className="m-2">
                                        <SearchForm searchLabel={"Enter Address"} onKeydown={handleKeyDown} />
                                    </Grid>

                                </div>
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                        <Box mt={4} py={0} id="ResultsPanelContainer">
                            <div>
                                <div id="searchResultsContainer" style={{ display: "none" }}>
                                    {/*<Box mb={2}>*/}
                                    {/*    <Typography variant="h6">Search Results</Typography>*/}
                                    {/*</Box>*/}
                                    <DataTable data="" columns={columns} order={[4, 'desc']} id="searchResults" />
                                </div>

                                <div id="searchResultsSkeleton" style={{ display: "none" }}>
                                    <Grid
                                        container
                                        spacing={0}
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center">
                                        <Skeleton variant="text" width="75%" animation="wave" />
                                        <Skeleton variant="rectangular" width='75%' height={300} animation="wave" />
                                    </Grid>
                                </div>
                            </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <div className="modal" id="srDetailsModal" >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Service Request Details</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" dangerouslySetInnerHTML={{ __html: srSearchResponse }}>

                        </div>
                        <div id="srDetailsSkeleton" style={{ display: "none" }}>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center">
                                <Skeleton variant="text" width="75%" animation="wave" />
                                <Skeleton variant="rectangular" width='75%' height={300} animation="wave" />
                            </Grid>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}