import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { useSelector, shallowEqual, useDispatch, connect } from "react-redux"
/*import { Link } from 'react-router-dom';*/
import './Footer.css';
//import userManager from "../utils/userManager";
//import Login from "../functions/login/components/login";

// Omit other footer components

export default class Footer extends React.PureComponent<{}, { isOpen: boolean, user: object }> {

    getYear() {
        return new Date().getFullYear();
    }

    // omit placeholder change handlers
    public render() {
        var logo = "/311/cagis logo.png";
        return (
            <div className="footer-clean">
                <footer>
                    <div className="container" style={{paddingBottom: "10px"}}>
{/*                        <div className="row">*/}
                            <div className="col-sm-4 col-md-3 item left">
                            <h3>Questions or Problems? </h3>
                            <ul>
                                <li><a href="https://www.cincinnati-oh.gov/ecc/about-ecc/311/" target="_blank">About 311Cincy</a></li>
                                <li><a href="https://city-cincinnatioh.govqa.us/WEBAPP/_rs/" target="_blank">Public Records Request</a></li>
                                {/*<li><a href="mailto:311cincy@cincinnati-oh.gov?subject=Requesting 311 Service">Send Email to 311</a></li>*/}
                                <li>Call <a href="tel:311">311</a> or <a href="tel:(513) 765-1212">(513) 765-1212</a></li>
                                <li>Available 24/7</li>
                                {/*{window.location.pathname.indexOf("SelectService") == -1 ?*/}
                                {/*   <div><li><a href="mailto:chandra.yungbluth@cincinnati-oh.gov; emily.venosa@cincinnati-oh.gov">Send Email to OCA</a></li>*/}
                                {/*     <li><a href="tel:513-352-1980">Call CAGIS - (513) 352-1980</a></li><div><li>7 a.m. to 5 p.m. Weekdays</li></div></div> : <li><a href="tel:513-591-6000">Call 591-6000</a></li>*/}
                                {/*}*/}

                                </ul>
                            </div>
                            {/*<div className="col-sm-4 col-md-3 item">*/}
                            {/*    <h3>About</h3>*/}
                            {/*    <ul>*/}
                            {/*        <li><a href="#">Company</a></li>*/}
                            {/*        <li><a href="#">Team</a></li>*/}
                            {/*        <li><a href="#">Legacy</a></li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                            {/*<div className="col-sm-4 col-md-3 item">*/}
                            {/*    <h3>Careers</h3>*/}
                            {/*    <ul>*/}
                            {/*        <li><a href="#">Job openings</a></li>*/}
                            {/*        <li><a href="#">Employee success</a></li>*/}
                            {/*        <li><a href="#">Benefits</a></li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                            <div className="col-lg-3 item social right">
                            <a href="https://www.facebook.com/311Cincy" target="_blank">
                                    <i className="bi-facebook"></i>
                                </a>
                            {/*<a href="https://twitter.com/311Cincy">*/}
                            {/*        <i className="icon bi bi-twitter"></i>*/}
                            {/*    </a>*/}
                            <a href="https://twitter.com/311Cincy" target="_blank">
                                    <i className="icon bi bi-twitter-x"></i>
                                </a>
                            <a href="https://instagram.com/311Cincy" target="_blank">
                                    <i className="icon bi-instagram">
                                    </i>
                            </a>
                            <p className="copyright" onClick={() => window.open('http://cagis.org/', '_blank')} style={{ cursor:'pointer'} }>CAGIS &#169; {this.getYear()}</p>
                            <img id="cagisLogo" onClick={() => window.open('http://cagis.org/', '_blank')} src={logo} style={{ height: 40, cursor:'pointer' }} alt="logo"/>
                            </div>
{/*                        </div>*/}
                    </div>
                </footer>
            </div>
        );
    }
}
