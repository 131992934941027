//import './css/jquery.dataTables.css'
import 'datatables.net-dt/css/jquery.dataTables.css'




import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'

import 'datatables.net-buttons-dt/css/buttons.dataTables.css'
import 'datatables.net-buttons-dt/js/buttons.dataTables.js'

import 'datatables.net-responsive/js/dataTables.responsive.js'
import 'datatables.net-responsive-dt/css/responsive.dataTables.css'

import React, { Component } from 'react'
import { connect } from 'react-redux';

import Responsive from 'datatables.net-responsive';

//import * as SampleStore from '../../store/SampleRequest';
const $ = require('jquery')
$.DataTable = require('datatables.net')

require('datatables.net-responsive');

//require('datatables.net-bs')(window, $);

 class DataTable extends Component {
    componentDidMount() {
        var theOrder = [[1, 'asc']]
        if (this.props.order != undefined) {
            theOrder = this.props.order
        }
        console.log(this.el);
        $(this.el).attr("id", this.props.id)
        this.$el = $(this.el)
        this.$el.DataTable({
            data: this.props.data,
            columns: this.props.columns,
            dom: 'Bfrtlip',
            buttons: [
                'copy', 'csv', 'excel', 'pdf', 'print'
            ],
            responsive: true,
            select: {
                style: 'os',
                selector: 'td:first-child'
            },
            order: theOrder,
            createdRow: function (row) {
                var td = $(row).find(".truncate");
                td.attr("title", td.html());
            }
        })
        console.log("Datatable-componentDidMount1=", this.props)
        if (this.props.rowClickFunc != null) {
            const myHist = this.props.history
            console.log("Datatable-componentDidMount2=",this.props)
            this.props.rowClickFunc(myHist);
        }
       
    }


    componentWillUnmount() {
    }


    render() {
        return (
            <div>
                <table className="display responsive nowrap collapsed" width="100%" ref={el => this.el = el}></table>              
            </div>
        )
    }
}

export default connect(
    (state) => state)(DataTable)