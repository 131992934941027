import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-icons/font/bootstrap-icons.css'
import React from 'react';
import ReactDOM from 'react-dom';
import CacheBuster from 'react-cache-buster';
import './index.css';
import App from './App';
import Layout from './Layout';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { version } from '../package.json';
import packageInfo from '../package.json'


const isProduction = process.env.NODE_ENV === 'production';

ReactDOM.render(
    <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
    //loadingComponent={} //If not pass, nothing appears at the time of new version check.
    >
        <React.StrictMode>
            <Provider store={store}>
                <Layout>
                    <App />
                </Layout>
            </Provider>
        </React.StrictMode>
    </CacheBuster>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
