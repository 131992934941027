import React, { Component } from 'react';
import FetchCSRServices from '../csr/FetchCSRServices'
import typeahead from 'jquery-typeahead'
import  'jquery-typeahead/src/jquery.typeahead.css'
import $ from 'jquery'

export class CSRServiceListTypeahead extends Component {

    constructor(props) {
        super(props);
        this.state = { services: [], loading: true };
    }

    componentDidMount() {
        var dataFetch = FetchCSRServices('CINC');
        dataFetch.then((data) => {
            this.setState({ services: data, loading: false });
        })
    }

    renderTypeahead(services) {
        var typeAheadDataObj = {};
        if (services.length > 0) {

            var userParam = "";
            //console.debug("this props: " + JSON.stringify(this.props));
            //let lclConnectUser = this.props.props.connectUser;
            //if (lclConnectUser != null) {
            //    var connectUser = lclConnectUser;
            //    var theGroup = "";
            //    if (connectUser.csrGroups != null) {
            //        theGroup = connectUser.csrGroups[0];
            //    }
            //    userParam = "&usr=" + connectUser.firstName + "%20" + connectUser.lastName + "&userOffice=" + theGroup
            //}

            //console.log(userParam);

            services.forEach(function (catObj) {
                var theCategory = catObj.id;
                if (theCategory != "COVID-19" && theCategory != null) {
                    var theServices = [];
                    var indexId = 0;
                    catObj.subtypes.forEach(function (catServAr) {
                        var url = "https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=" + catServAr.id;
                        if (catServAr.url) {
                            url = catServAr.url
                        }
                        if (catServAr.modal) {
                            url = "javascript:void(0);"

                        }
                        //theServices.push(catServAr.description);
                        theServices.push({
                            id: indexId++,
                            description: catServAr.description,
                            purpose: catServAr.purpose,
                            tags: catServAr.tags,
                            short: catServAr.id,
                            url: url,
                            modal: catServAr.modal
                        })
                    })
                    catObj.subgroups.forEach(function (catSubAr) {
                        //theServices.push(catServAr.description);
                        catSubAr.subtypes.forEach(function (catSubServAr) {
                            //theServices.push(catServAr.description);
                            var url = "https://cagismaps.hamilton-co.org/CSR/cincinnati/serviceRequest?srtype=" + catSubServAr.id;
                            if (catSubServAr.url) {
                                url = catSubServAr.url
                            }
                            theServices.push({
                                id: indexId++,
                                description: catSubServAr.description,
                                purpose: catSubServAr.purpose,
                                tags: catSubServAr.tags,
                                short: catSubServAr.id,
                                url: url,
                                modal: catSubServAr.modal
                            })
                        })
                    })

                    typeAheadDataObj[catObj.id] = {
                        data: theServices
                    }
                }
            }, this)

            var typeah = $.typeahead({
                input: '.js-typeahead-country_v1',
                order: "desc",
                group: {
                    template: "{{group}}"
                },
                display: ['description','purpose', 'tags'],
                template: "<span>{{description}}</span>",
                source: typeAheadDataObj,
                debug: true,
                href: "{{url}}",
                onClick: "alert({{modal}})",
                callback: {
                    onClickBefore: function (node, query, result) {
                        if (result.modal) {
                            alert(result.modal)
                        }
                    },
                    onInit: function (node) {
                        //console.log('Typeahead Initiated on ' + node.selector);
                    },
                    onResult: function (node, query, result, resultCount, resultCountPerGroup) {
                        console.log(node, query, result, resultCount, resultCountPerGroup);
                    }
                }
            })
        }

        //return (
        //    <div className="typeahead__container" style={{ innerWidth: "500px" }}>
        //        <div className="typeahead__field">
        //            <div className="typeahead__query">
        //                <input className="js-typeahead-country_v1" name="country_v1[query]" placeholder="Search" autocomplete="off"></input>
        //            </div>
        //            <div className="typeahead__button">
        //                <button type="submit">
        //                    <i className="typeahead__search-icon"></i>
        //                </button>
        //            </div>
        //        </div>
        //    </div>
        //);
    }

    render() {
        let contents =
            <div className="typeahead__container" style={{ innerWidth: "500px" }}>
            <div className="typeahead__field">
                <div className="typeahead__query">
                    <input className="js-typeahead-country_v1" name="country_v1[query]" placeholder="Search" autoComplete="off"></input>
                </div>
                <div className="typeahead__button">
                    <button type="submit">
                        <i className="typeahead__search-icon"></i>
                    </button>
                </div>
            </div>
        </div>

        this.renderTypeahead(this.state.services);

        return (
            <div>
                {contents}
            </div>
        );
    }

}
