import * as React from 'react';
import { Container } from 'reactstrap';
import Footer from './Footer';
import Pdf from '../src/311FAQ.pdf';
import Box from '@mui/material/Box';
import { grey } from "@mui/material/colors"

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    static displayName = "Test"; //Layout.name;
    public render() {
        return (
            <React.Fragment>
                {/*<NavMenu />*/}
                <Container>
                    {this.props.children}
                    {/*<Box my={5} pl={3} pr={3} pb={2} bgcolor={grey[100]}*/}
                    {/*    boxShadow={2} border={1} borderRadius={8} borderColor="grey.400" className="text-center">*/}
                    {/*    <a style={{ fontSize: '27px', textDecoration: 'none', padding: '20px 0px 6px 0px', display: 'block'}} */}
                    {/*        href={Pdf} target="_blank"><i className="bi bi-file-earmark-arrow-down"></i> Download 311 FAQ</a></Box>*/}
                </Container>

                <Footer />
            </React.Fragment>
        );
    }
}