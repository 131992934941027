import * as React from 'react';

const LOCAL_RSS_URLS = ["https://content.getrave.com/rss/cincinnati/channel1","","https://content.getrave.com/rss/cincinnati/channel3"];
//const LOCAL_RSS_URLS = ["https://digs-test.coc.ads/themes/cityofcincinnati-bootstrap4/js/custom/rsschannel1.xml"];
const rssFeedSearchValue = "all clear";
const $ = require('jquery');

function startRSSFeed() {
	for (let i = 0; i < LOCAL_RSS_URLS.length; i++) {
		getRSSFeed(LOCAL_RSS_URLS[i],i);
	}
}

function getRSSFeed(apiUrl: string, channel: number) {
	$.ajax({
		url: apiUrl,
		accepts: {
			xml: "application/rss+xml"
		},
		dataType: "xml",
		success: function (data:any) {
			$(data).find('item').each(function (i:number,a:any) {
				displayRSSFeedData($(a),channel);
			});	
		}
	});
}

interface Item {
	title: string,
	link: string,
	description: string,
	pubDate: string,
	guid: string,
	date: string
}

function displayRSSFeedData(el: any, channel: number) {
	var rssFeed = el.find("description").text().toLowerCase();
	var pubDateobj = new Date(el.find("pubDate").text());
	var strdate = getdatetime(pubDateobj);
	var backgroundColor = channel == 0 ? "#ff0000" : "#ffa800";
	var backgroundTextColor = channel == 0 ? "#FFF" : "#000";
	const template = `<div class="container-fluid" style="background-color: ` + backgroundColor + `; color: ` + backgroundTextColor + `; font-size: 18px; width:100%">
				<div class="container">				
					<div class="row" style="margin-left:auto; margin-right:auto;">						
						<div class="col-md-9 text-md-left my-auto" style="padding-left: 0px;">
							<span>${el.find("description").text()}&nbsp;(Updated: ${strdate})</span>
						</div>
						<div class="col-md-2 text-md-right my-auto" style="padding-left: 0px;">
							<a href="https://www.cincinnati-oh.gov/ecc/alerts/" target="_blank" style="color: #0a323e;">View more or sign up</a>
						</div>
						<div class="col-md-1 my-auto" style="padding-left: 0px; text-align: right;">
							<a href="https://www.cincinnati-oh.gov/ecc/alerts/" target="_blank">
								<img src="https://www.cincinnati-oh.gov/themes/cityofcincinnati-bootstrap4/js/custom/logo_cincy_alert_white_1219.png" 
									style="padding-top: 5px; padding-bottom: 5px; width: 75px; height:65px;"></a>
						</div>
				
					</div>
				</div>
			</div>`;


	if (rssFeed.indexOf(rssFeedSearchValue) == -1) {
		//$("#header").after(template);
		$(".featured-wrap" + channel).css('position', 'initial');
		$(".featured-wrap" + channel).html(template);
	}
}

function getdatetime(dt: any) {
	var res = "";
	res += formatdigits(dt.getMonth() + 1);
	res += "/";
	res += formatdigits(dt.getDate());
	res += "/";
	res += formatdigits(dt.getFullYear());
	res += " ";
	res += formatdigits(dt.getHours() > 12 ? dt.getHours() - 12 : dt.getHours());
	res += ":";
	res += formatdigits(dt.getMinutes());
	res += " " + (dt.getHours() > 11 ? " PM" : " AM");
	return res;
}
function formatdigits(val :any) {
	val = val.toString();
	return val.length == 1 ? "0" + val : val;
}

export default function CSR_RssBanner() {

   startRSSFeed();

	return (<>
		<div className="featured-wrap0"></div>
		<div className="featured-wrap2"></div>
	</>);
}