
async function FetchCSRServices(juris) {
    const response = await fetch('https://cagis.hamilton-co.org/CSRServices/api/v1/CSRConfiguration/GetAvailableTypes?juris=' + juris);
    //const response = await fetch('https://cagwebapptest.coc.ads/CSRServices/api/v1/CSRConfiguration/GetAvailableTypes?juris=' + juris);
    //const response = await fetch('https://localhost:44385/api/v1/CSRConfiguration/GetAvailableTypes?juris=' + juris);
    //const response = await fetch('https://cagis.hamilton-co.org/CSRServicesTest/api/v1/CSRConfiguration/GetAvailableTypes?juris=' + juris);
    const data = await response.json()
    return data;
}

export default FetchCSRServices